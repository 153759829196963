import { Box, Typography, Pagination } from "@mui/material";
import { MetaData } from "../models/Pagination";
import { useState } from "react";

interface Props{
    metaData: MetaData;
    onPageChange: (page: number) => void;
}

export default function AppPagination({metaData, onPageChange} : Props){
    const {currentPage, totalPages, totalCount, pageSize} = metaData;
    const [pageNumber, setPageNumber] = useState(currentPage);

    function handlePageChange(page: number) {
        setPageNumber(page);
        onPageChange(page);
    }
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Typography>
            Displaying {(currentPage-1)*pageSize+1} - 
            {currentPage * pageSize > totalCount
                ? totalCount
                : currentPage * pageSize} of {totalCount} items
        </Typography>
        <Pagination 
            color="secondary"
            size="large"
            count={totalPages}
            page={pageNumber}
            onChange={(e, page) => handlePageChange(page) }
        />
        </Box>
    );
}